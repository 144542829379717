import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'
import { skyBlue } from '../../constants/theme';

export const SiteFooterStyle = styled.footer`
  margin: 0;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 11px;
  background-color: ${skyBlue};
  a {
    display: inline-block;
    padding: 20px 0;
    color: white;
    text-transform: uppercase;
    text-decoration: none;

    &:first-of-type {
      padding-top: 20px;
    }

    @media(max-width: ${ breakpoints.m}px) {
      padding: 0;
    }

    &:hover {
      text-decoration: underline;
    }

  }
`
export const GridLeft = styled.div`
  text-align: left;
  p {
    display: inline-block;
    padding: 20px 0;
    margin: 0;
  }
`;

export const GridRight = styled.div`
  text-align: right;
  a {
    padding-left: 6px;
  }
`;
