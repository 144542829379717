/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, useEffect, useContext } from 'react'
import { useForm } from '@formcarry/react'
import sha1 from 'sha1';
import ireTrack from '../../utils/ireTrack'
import StoreContext from '../../context/StoreContext'
import styled from '@emotion/styled'
import validator from 'validator'

const FormText = styled.div`
  color: white;
  text-transform: uppercase;
`;


const FooterFormContain = ({title, title2, submit:submitText, source }) => {
  const {checkout} = useContext(StoreContext)
  const [submitReady, setSubmitReady] = useState(false);
  const [theEmail, setEmail] = useState(false);

  const validateEmail = (e) => {
    var email = e.target.value
  
    if (validator.isEmail(email)) {
      setSubmitReady(true)
      setEmail(email)
    } else {
      setSubmitReady(false)
    }
  }

  const {state, submit} = useForm({
    id: 'dnGUtvGpjeU0'
  });

  useEffect(() => {
    if (state.submitted) { 
      ireTrack('trackConversion', 24948,
        {
          orderId: checkout.id ? checkout.id : "",
          customerId: "",
          customerEmail: sha1(theEmail)
        },
        {
          verifySiteDefinitionMatch: true
        }
      )
    }
  }, [theEmail, state.submitted, checkout])

  return (
    <div>
      <form onSubmit={submit} id="quick-contact">
        <input type="hidden" name="source" value={source}/>
        <input type="hidden" name="form-name" value="quick-subscribe" />
        <div className="form-contain">
            <div className="form-header">
              <h1>
                {title}
              </h1>
              <h3>
                {title2}
              </h3>
            </div>

          {state.submitted && <FormText>Thank you! You are now subscribed</FormText> }

          {!state.submitted &&
            <>
              <label htmlFor="contactFormName">Name</label>
              <input type="text" id="contactFormName" name="name" />
              <label htmlFor="contactFormEmail">Email</label>
              <input type="email" onChange={(e) => validateEmail(e)} name="email" autoCorrect="off" id="contactFormEmail" autoCapitalize="off" />
              <input type="submit" disabled={!submitReady} value={submitText} className="btn btn-submit" />
            </>
          }

        </div>
      </form>
    </div>
  )
}

export default FooterFormContain
