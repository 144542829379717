import React, { useState } from 'react';
import { InstantSearch, Index } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import client from './client';
import Hits from './Hits';
import { Wrapper, SearchBox, Results } from './styles';

const SearchBar = React.forwardRef(({ deactivate }, ref) => {
  const mainIndex = 'products';
  const initialIndex = 'products_recently_ordered_count_desc';
  const [index, setIndex] = useState(initialIndex);

  const [searchStateChangeTimer, setSearchStateChangeTimer] = useState(null);
  const typingTimer = 500;

  return (
    <Wrapper vertical>
      <InstantSearch
        searchClient={client}
        indexName={index}
        onSearchStateChange={searchState => {
          // Check to see what index is being used. If it is not the main
          // index, and we are on search state change, then we have started
          // typing and need to change it to the main index. If we are already
          // on the main index, we check to see if the current query is blank.
          // If it is blank, then we set it back to our initial/empty results
          // index to be used instead.
          if (index !== mainIndex) {
            setIndex(mainIndex);
          } else if (searchState.query === '') {
            setIndex(initialIndex);
          }

          // Clear any existing intervals
          if (searchStateChangeTimer) {
            clearInterval(searchStateChangeTimer);
          }

          // Create a new timer function.
          setSearchStateChangeTimer(setTimeout(() => {
            const page = `/?query=${searchState.query}`;

            if (window && window.ga) {
              window.ga('send', 'pageview', page);

              if (window && window.ga_debug) {
                console.log(`ga send pageview ${page}`);
              }
            }

            setSearchStateChangeTimer(null);
          }, typingTimer));
        }}
      >
        <Wrapper>
          <SearchBox submit={false} reset={false} autofocus={true}/>
          <button className="search-close" onClick={deactivate}>
            <FontAwesomeIcon icon={faWindowClose} size="2x"/>
          </button>
        </Wrapper>

        <Results ref={ref}>
          <Index indexName="collections">
            <Hits deactivate={deactivate} type="collections" max={4} />
          </Index>
          <Index indexName={index}>
            <Hits deactivate={deactivate} type="products" max={8} />
          </Index>
          <Index indexName="articles">
            <Hits deactivate={deactivate} type="articles" max={2} />
          </Index>
        </Results>
      </InstantSearch>
    </Wrapper>
  );
});

export default SearchBar;
