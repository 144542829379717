export default function ireTrack(eventType, convertId, options, extra) {
  if (process.env.NODE_ENV === `production` && typeof window !== "undefined") {
    if (window.ire != null) {
      if (eventType === 'identify') {
        window.ire('identify');
      }
      if (eventType === 'trackConversion') {
        window.ire('trackConversion', convertId, options, extra);
      } else {
        // window.ire('trackConversion', convertId, options);
      }
    }
  }
}
