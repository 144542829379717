import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from "@emotion/core"

import { accent, skyBlue, copper, oceanBlue, tealGreen } from '../constants/theme';

import copperStripeJPG from '../images/copper-stripe.jpg';

import ccBackdrop from '../images/cc-backdrop.png';

import lifestyleImage from '../images/lifestyle.jpg';

import GilroyLightwoff from '../fonts/gilroy-light-webfont.woff';
import GilroyLightwoff2 from '../fonts/gilroy-light-webfont.woff2';
import GilroyExtraBoldwoff from '../fonts/gilroy-extrabold-webfont.woff';
import GilroyExtraBoldwoff2 from '../fonts/gilroy-extrabold-webfont.woff2';
import HKNovaBoldwoff from '../fonts/hknova-bold-webfont.woff';
import HKNovaBoldwoff2 from '../fonts/hknova-bold-webfont.woff2';
import HKNovaBoldNarrowwoff from '../fonts/hknova-boldnarrow-webfont.woff';
import HKNovaBoldNarrowwoff2 from '../fonts/hknova-boldnarrow-webfont.woff2';
import HKNovaExtraBoldwoff from '../fonts/hknova-extrabold-webfont.woff';
import HKNovaExtraBoldwoff2 from '../fonts/hknova-extrabold-webfont.woff2';
import HKNovaHeavyNarrowwoff from '../fonts/hknova-heavynarrow-webfont.woff';
import HKNovaHeavyNarrowwoff2 from '../fonts/hknova-heavynarrow-webfont.woff2';
import HKNovaMediumwoff from '../fonts/hknova-medium-webfont.woff';
import HKNovaMediumwoff2 from '../fonts/hknova-medium-webfont.woff2';
import HKNovaMediumNarrowwoff from '../fonts/hknova-mediumnarrow-webfont.woff';
import HKNovaMediumNarrowwoff2 from '../fonts/hknova-mediumnarrow-webfont.woff2';
import HKNovaNarrowwoff from '../fonts/hknova-narrow-webfont.woff';
import HKNovaNarrowwoff2 from '../fonts/hknova-narrow-webfont.woff2';
import HKNovaRegularwoff from '../fonts/hknova-regular-webfont.woff';
import HKNovaRegularwoff2 from '../fonts/hknova-regular-webfont.woff2';
import HKNovaRegularRwoff from '../fonts/hknova-regularr-webfont.woff';
import HKNovaRegularRwoff2 from '../fonts/hknova-regularr-webfont.woff2';
import HKNovaSemiboldwoff from '../fonts/hknova-semibold-webfont.woff';
import HKNovaSemiboldwoff2 from '../fonts/hknova-semibold-webfont.woff2';


export const breakpoints = {
  xs: 340,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = props => (
  <Global
    { ...props }
    styles={ css`
      body {
        margin: 0;
        background: #fff;
        font-size: 16px;
        &.nav-open {
          overflow: hidden;
        }
      }

      html {
        &.no-scroll {
          overflow: hidden;
        }
      }

      .slick-slider {

        @media (max-width: ${breakpoints.m}px){
          align-items: flex-end;
          padding-bottom: 0;
        }

        &.product-slider {
          height: auto;
          margin: 0;
        }
      }

      .product-nav-slider .slick-slide {
        padding: 0;
        box-sizing: border-box;
      }

      .product-nav-slider .slick-current {
        background-color: ${copper};
        img {
          filter: opacity(80%);
        }
      }


      .slick-slide {

        pointer-events: none;

        &.slick-active {
          pointer-events: auto;
        }

      }

      .product-slider {
        .slick-slide {
          height: unset;
          & > div {
            height: unset;
          }
        }
      }


      .slide-slide > div {
        height: 100%;
      }

      .slick-slide picture {
        height: 100%;
        object-fit: contain;
      }

      @font-face {
          font-family: 'HKNova-Bold';
          src: url(${HKNovaBoldwoff2}) format('woff2'),
              url(${HKNovaBoldwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-BoldNarrow';
          src: url(${HKNovaBoldNarrowwoff2}) format('woff2'),
              url(${HKNovaBoldNarrowwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-ExtraBold';
          src: url(${HKNovaExtraBoldwoff2}) format('woff2'),
              url(${HKNovaExtraBoldwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-HeavyNarrow';
          src: url(${HKNovaHeavyNarrowwoff2}) format('woff2'),
              url(${HKNovaHeavyNarrowwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-Medium';
          src: url(${HKNovaMediumwoff2}) format('woff2'),
              url(${HKNovaMediumwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-MediumNarrow';
          src: url(${HKNovaMediumNarrowwoff2}) format('woff2'),
              url(${HKNovaMediumNarrowwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-Narrow';
          src: url(${HKNovaNarrowwoff2}) format('woff2'),
               url(${HKNovaNarrowwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-Regular';
          src: url(${HKNovaRegularwoff2}) format('woff2'),
               url(${HKNovaRegularwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
          font-family: 'HKNova-RegularR';
          src: url(${HKNovaRegularRwoff2}) format('woff2'),
              url(${HKNovaRegularRwoff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }

      @font-face {
        font-family: 'HKNova-SemiBold';
        src: url(${HKNovaSemiboldwoff2}) format('woff2'),
          url(${HKNovaSemiboldwoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'gilroyLight';
        src: url(${GilroyLightwoff2}) format('woff2'),
          url(${GilroyLightwoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'gilroyExtrabold';
        src: url(${GilroyExtraBoldwoff2}) format('woff2'),
          url(${GilroyExtraBoldwoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }

      html {
        font-family: 'gilroyLight', sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }

      a {
        color: ${accent};
      }

    `}
  />
)

export const Img = styled(Image)`
  max-width: 100%;
  margin: 0;
  padding: 0;
`

export const Describe = styled.div`
  h1 {
    font-size: 20px;
    margin-top: 50px;
  }
  img {
    width: 100%;
  }
  padding: 30px;
`;


export const ContentBlock = styled.div`
  h1 {
    font-size: 20px;
    margin-top: 50px;
  }
  img {
    width: 100%;
  }
  padding: 30px;
`;

export const PrezBlockStyle = styled.div`
  h1 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  h4 {
    padding: 0;
    color: ${oceanBlue};
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
  }
  padding: 0;
`;

export const HeroBlockStyle = styled.div`

  background-color: white;
  padding: 30px;
  padding-bottom: 80px;
  margin-top: 20px;
  color: white;
  display: flex;
  text-align: center;

  align-items: center;

  @media(max-width: ${breakpoints.m}px) {
    flex-direction: column;
  }

  .fluid-media {
    position: relative;
    padding-bottom: 40%;
    height: 0;
    width: 100%;
    overflow: hidden;

    @media(max-width: ${breakpoints.m}px) {
      padding-bottom: 56.25%;
    }

  }

  .fluid-media iframe {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
  }

  .copy {
    width: 100%;
    box-sizing: border-box;
    color: ${oceanBlue};
    @media(max-width: ${breakpoints.m}px) {
      width: 100%;
    }

  }


  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  h1 {
    font-size: 72px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
    position: relative;
    color: ${copper};
    display: inline-block;
    /* &::after {
      content: '™';
      position: absolute;
      top: 8px;
      right: -12px;
      font-size: 24px;
    } */
  }
  p {
    font-size: 14px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 600px;
    margin: auto;
  }

`;

export const ButtonStyle = styled.div`
    margin: auto;
    margin-top: 15px;
    border: 0;
    display: inline-block;

    a {
        text-align: center;
        background-color: ${copper};
        font-size: 13px;
        font-family: 'HKNova-ExtraBold';
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        display: block;
        color: white;
        text-decoration: none;
        padding: 16px 20px 20px 20px;
        border-radius: 12px;
        &:hover {
            background-color: #1f2c44;
        }
        &:active {
            background-color: white;
            color: ${copper};
        }
    }

    &:focus {
        border: 0;
        outline: 0;
    }
    
`;


export const SalesBarStyle = styled.div`
  margin-top: 174px;
  text-align: center;

  * { box-sizing: border-box; }

  &.no-top {
    margin-top: -1px;
    position:fixed;
    z-index: 20;
    width: 100%;
  }

  a {
      display: block;
      width: 100%;
      padding: 10px;
      color: white;
        font-size: 13px;
        font-family: 'HKNova-Regular';
        letter-spacing: 0.1rem;
        line-height: 1.4;
        max-width: 500px;
        margin: auto;
        text-decoration: none;

  }

  .interior {
      display: block;
      width: 100%;
      padding: 10px;
      color: white;
        font-size: 13px;
        font-family: 'HKNova-Regular';
        letter-spacing: 0.1rem;
        line-height: 1.4;
        max-width: 500px;
        margin: auto;
        text-decoration: none;

  }

  
  border: 0;
  background-color: ${oceanBlue};
  &:focus {
    border: 0;
    outline: 0;
  }
  &:hover {
    background-color: #1f2c44;
  }
  h3 {
  }
  .coupon-code {
    display: inline-block;
    svg {
      margin-right: 5px;
    }
    background-color: ${copper};
    border-radius: 12px;
    color: white;
    padding: 1px 5px 4px 5px;
    margin: 5px;
    font-size: 11px;
  }
`;

export const ImageBlockStyle = styled.div`

  .slide-area {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 33%;

    @media (max-width: ${breakpoints.m}px){
      padding-bottom: 45%;
    }

    @media (max-width: ${breakpoints.s}px){
      padding-bottom: 50%;
    }

  }

  img {
    position:absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }

  .caption-area {
    position: absolute;
    top: 0;
    left: auto;
    right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    height: 100%;
    padding-left: 30px;
    @media (max-width: ${breakpoints.s}px){
      align-items: center;
      text-align: center;
      padding-left: 0%;
    }

  }

  a {
    display: block;
    background-color: ${copper};
    color: white;
    text-decoration: none;
    padding: 10px;
    width: 200px;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);


    @media (max-width: ${breakpoints.m}px){
      font-size: 14px;
    }



    @media (max-width: ${breakpoints.s}px){
      padding: 8px 16px;
      width: auto;
      font-size: 12px;
    }



  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;
    color: white;


    @media (max-width: ${breakpoints.m}px){
      font-size: 12px;
    }

    @media (max-width: ${breakpoints.s}px){
      font-size: 10px;
    }

    color:  ${props => props.invert ? oceanBlue : 'white'};

  }

  h1 {
    font-size: 72px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
    position: relative;
    color:  ${props => props.invert ? copper : copper};
    display: block;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    text-align: left;




    &.has-trademark {
      &::after {
        content: '™';
        position: absolute;
        top: 8px;
        right: -12px;
        font-size: 24px;

        @media (max-width: ${breakpoints.s}px){
          right: 2px;
        }
      }
    }


    @media (max-width: ${breakpoints.xl}px){
      font-size: 60px;
    }


    @media (max-width: ${breakpoints.l}px){
      font-size: 48px;
    }

    @media (max-width: ${breakpoints.m}px){
      font-size: 42px;
    }


    @media (max-width: ${breakpoints.s}px){
      text-align: center;
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
`;


export const HeroImageBlockStyle = styled.div`

  background-image: ${props => props.backgroundImage};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  @media (max-width: ${breakpoints.m}px){
    background-position: top center;
    background-size: auto 72%;
  }

  @media (max-width: ${breakpoints.s}px){
    background-position: top center;
    background-size: auto 68%;
  }


  &.has-heightcap {
    background-size: auto 100%;

    @media (max-width: ${breakpoints.m}px){
      background-position: top center;
      background-size: auto 83%;
    }

    @media (max-width: ${breakpoints.s}px){
      background-position: top center;
      background-size: auto 70%;
    }

    /* @media (max-width: ${breakpoints.xs}px){
      background-position: top center;
      background-size: auto 83%;
    } */
  }


  .slide-area {
    height: 100vh;
    padding: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    @media (min-height: 900px) {
      height: calc(100vh - 210px);
    }

    @media (max-width: ${breakpoints.m}px){
      height: calc(75vh);
      align-items: flex-end;
      padding-bottom: 10px;
    }
  }

  &.extraTop {
    margin-top: 174px;
  }

  a {
    display: block;
    background-color: ${copper};
    color: white;
    text-decoration: none;
    padding: 10px;
    width: 200px;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;
  }

  h1 {
    font-size: 72px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
    position: relative;
    color: ${copper};
    display: inline-block;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2);

    &.has-trademark {
      &::after {
        content: '™';
        position: absolute;
        top: 8px;
        right: -12px;
        font-size: 24px;

        @media (max-width: ${breakpoints.s}px){
          right: 2px;
        }
      }
    }

    @media (max-width: ${breakpoints.s}px){
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
`;

export const Interior = styled.div`
  background-color: rgba(124, 60, 10, 0.12);
  border: 1px solid #7C3C0A;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1320px;
  padding: 0 38px;
  &.extraTop {
    margin-top: 48px;
  }

  @media (max-width: ${breakpoints.m}px){
    padding: 0;
  }
`

export const ImitationsBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  .grid {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @media (max-width: ${breakpoints.s}px){
      grid-template-columns: repeat(1, 1fr);
    }
  }

  p {
    font-size: 14px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 600px;
    margin: auto;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  img {
    border-radius: 100%;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }
`;


export const PressItemsBlockStyle = styled.div`

  box-sizing: border-box;
  text-align: center;
  margin-bottom: 100px;

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 7px;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
  }

  .contain {
    max-width: 1240px;
    display: flex;
    margin: auto;
    justify-content: space-around;
    padding: 0 30px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2.5rem;

    @media (max-width: ${breakpoints.l}px){
      max-width: 820px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${breakpoints.m}px){
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${breakpoints.s}px){
      grid-template-columns: repeat(1, 1fr);
      gap: 0rem;
    }

  }

  .press-item {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakpoints.s}px){
      flex-direction: row;
      text-align: left;
    }

    img {
      max-width: 100px;
      height: 100px;
      object-fit: contain;
    }

    p {
      padding: 0;
      font-size: 12px;
      font-family: 'HKNova-Regular';
      letter-spacing: 0.1rem;
      line-height: 1.4;
      margin: auto;
      color: ${oceanBlue};

      @media(max-width: ${breakpoints.s}px) {
        font-family: 'HKNova-Medium';
        font-size: 13px;
        padding-left: 40px;
      }

    }

    &:hover {
      transform: translateY(-2px);
    }
  }
`;


export const ImageStripBlockStyle = styled.div`
  width: 100%;
`;

export const LifestyleBlockStyle = styled.div`
  background-image: url(${lifestyleImage});
  height: 50vh;
  width: 100%;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.l}px){
    height: 33vh;
  }

  @media (max-width: ${breakpoints.s}px){
    background-attachment: scroll;
    height: 300px;
  }
`;

export const ReviewBlockStyle = styled.div`

  color: ${props => props.darkBG ? '#fff' : oceanBlue};
  background-color: ${props => props.bgColor ? props.bgColor : "rgba(71,131,181,.16)"};

  display: flex;
  margin-bottom: 0;
  flex-direction: row-reverse;
  justify-content: center;

  @media(max-width: ${breakpoints.m}px) {
    flex-wrap: wrap;
    max-height: unset;
    height: unset;
  }

  h2 {
    margin: 0;
    color: ${skyBlue};
    font-size: 28px;
    position: relative;
    text-align: right;
    &:before {
      content: '"';
      opacity: 0.5;
      padding-right: 6px;
      font-size: 42px;
      line-height: 0;
    }
    &:after {
      content: '"';
      opacity: 0.5;
      padding-left: 6px;
      font-size: 36px;
      position: absolute;
    }
  }

  h3, h5 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    text-align: right;
    font-size: 12px;
    margin-bottom: 21px;
  }

  h3 {
    max-width: 320px;
  }

  h4 {
    color: ${copper};
    font-size: 48px;
    margin: 0;
  }

  h5 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  h1 {
    font-size: ${props => props.hyperTitle ? '72px' : '46px'};

    @media (max-width: 800px){
      font-size: ${props => props.hyperTitle ? '46px' : '38px'};
    }

    color: ${props => {

    if (props.darkBG && !props.hyperTitle) {
      return '#fff'
    } else if (props.darkBG && props.hyperTitle) {
      return skyBlue
    } else if (props.hyperTitle) {
      return skyBlue
    }

    return oceanBlue

  }

  };


    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
  }

  p {
    font-size: 12px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    max-width: 320px;
    margin-left: 0;
    text-align: justify;
    line-height: 1.4;
  }

  .cta {
    display: block;
    background-color: ${skyBlue};
    color: white;
    text-decoration: none;
    padding: 10px;
    width: 200px;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    margin-top: 30px;
  }

  .box {
    display: flex;
    width: 50%;
    max-width: 660px;
    align-items: center;
    padding: 0;

    border-bottom: 4px solid ${skyBlue};
    background-color: #ffffff;

    justify-content: flex-end;

    @media(max-width: ${breakpoints.m}px) {
      border-bottom: 0;
      width: 100vw;
    }
  }

  h6 {
    color: white;
    padding: 4px;
    background-color: #ff9900;
    margin-bottom: 10px;
  }

  .review-count {
    padding: 20px 0;
  }

  .caption-area {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: 20px;
    padding-bottom: 20px;
  }

  .box.box-image {

    padding: 0;

    margin: 0;

    overflow: auto;
    align-items: flex-end;
    img {
      width: 100%;
      display: block;
      max-width: 800px;
    }

    border-bottom: 4px solid ${skyBlue};


    @media (min-width: 1680px){ 
      width: 800px;
      justify-content: ${props => props.mirror ? 'flex-end' : 'flex-start'};
    }

    @media(max-width: ${breakpoints.m}px) {
      padding-top: 50px;

      img {
        width: 100%;
        margin: auto;
        display: block;
      }
    }

  }

`;

export const StickerBlockStyle = styled.div`

  color: ${props => props.darkBG ? '#fff' : oceanBlue};
  /* margin-top: ${props => props.firstBlock ? '34px' : '0'}; */
  background-color: ${props => props.bgColor ? props.bgColor : "rgba(71,131,181,.16)"};


  padding-bottom: ${props => props.tightBaseline ? '0' : '50px'};
  padding-top: ${props => props.tightBaseline ? '0' : '50px'};


  /* max-height: 800px;
  min-height: 400px; */
  display: flex;

  @media (min-width: 800px){
    flex-direction: ${props => props.mirror ? 'row-reverse' : 'row'};
  }

  @media(max-width: ${breakpoints.l}px) {
    flex-wrap: wrap;
    max-height: unset;
    height: unset;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
  }

  h1 {
    font-size: ${props => props.hyperTitle ? '72px' : '46px'};

    @media (max-width: 800px){
      font-size: ${props => props.hyperTitle ? '46px' : '38px'};
    }

    color: ${props => {

    if (props.darkBG && !props.hyperTitle) {
      return '#fff'
    } else if (props.darkBG && props.hyperTitle) {
      return skyBlue
    } else if (props.hyperTitle) {
      return skyBlue
    }

    return oceanBlue

  }

  };


    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
  }

  p {
    font-size: 12px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    max-width: 320px;
    margin-left: 0;
    text-align: justify;
    line-height: 1.4;
  }

  .cta {
    display: block;
    background-color: ${copper};
    color: white;
    text-decoration: none;
    padding: 10px;
    width: 200px;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  }

  .box {
    display: flex;
    width: 50%;
    align-items: center;
    padding: 20px;

    padding: ${props => props.tightBaseline ? '50px 20px' : '20px'};

    justify-content: center;

    @media(max-width: ${breakpoints.l}px) {
      width: 100vw;
    }
  }

  .footer-form-contain {
    margin: 0;
    padding: 0;

    .form-header {
      padding: 0;
      margin: 20px 0;
      h3 {
        margin: 0;
        color: ${oceanBlue};
        font-size: 14px;
        font-family: 'HKNova-Regular';
        letter-spacing: 0.1rem;
        line-height: 1.4;
      }
    }


    .form-contain {
      label {
        color: ${oceanBlue};
      }
      input[type=email] {
        background-color: rgba(71,131,181,0.16);
        color: ${oceanBlue};
        border-bottom: 2px solid ${skyBlue};
      }
      margin: 0;
    }
  }

  .caption-area {
    max-width: 400px;
  }

  .box.box-image {

    padding: 0;

    img {
      width: 100%;
      max-width: 800px;
    }

    @media (min-width: 1680px){ 
      width: 800px;
      justify-content: ${props => props.mirror ? 'flex-end' : 'flex-start'};
    }

    @media(max-width: ${breakpoints.l}px) {
      padding-top: 50px;

      img {
        width: 90%;
        margin: auto;
        display: block;
      }
    }

  }

`;

export const MaskBlockStyle = styled.div`

  background-color: ${oceanBlue};
  color: white;
  padding: 40px;

  &.first-block {
    margin-top: 174px;
  }

  @media (max-width: 800px){
    padding: 40px 20px;
  }

  img {
    height: 50vh;
    @media (max-width: 1160px){
      height: auto;
      width: 100%;
    }
  }

  .contain {
    max-width: 1320px;
    margin: auto;
    display: flex;
    flex-direction: row;

    @media (max-width: 1160px){
      display: block;
    }

    .box {
      width: 50%;
    }

  }


  p {
    font-size: 12px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    max-width: 320px;
    margin-left: 0;
    text-align: justify;
    line-height: 1.4;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 72px;
    font-family: 'HKNova-Bold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1;
    color: ${tealGreen};
    margin-bottom: 50px;

    @media(max-width: ${breakpoints.m}px) {
      font-size: 48px;
    }

    @media(max-width: ${breakpoints.s}px) {
      font-size: 32px;
    }
  }

  a {
    color: white;
    display: block;
    margin-top: 20px;
    text-decoration: none;
  }
`;

export const GuaranteeBlockStyle = styled.div`
  background-color: ${oceanBlue};
  color: white;
  padding: 40px;

  background-image: url(${ccBackdrop});


  background-size: contain;
  background-repeat: no-repeat;
  background-position: -25% bottom;

  .contain {
    max-width: 1320px;
    margin: auto;
  }

  p {
    font-size: 12px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    max-width: 320px;
    margin-left: auto;
    margin-right: 20px;
    text-align: justify;
    line-height: 1.4;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 72px;
    font-family: 'HKNova-Bold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1;
    color: ${tealGreen};
    margin-bottom: 50px;

    @media(max-width: ${breakpoints.m}px) {
      font-size: 48px;
    }

    @media(max-width: ${breakpoints.s}px) {
      font-size: 32px;
    }
  }

  a {
    color: white;
    display: block;
    margin-top: 20px;
    text-decoration: none;
  }
`;

export const TwoColumnGrid = styled.div`
  display: flex;
  padding: 20px;

  @media(max-width: ${breakpoints.m}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  width: 320px;
  flex-grow: 1;
  h1 {
    font-size: 14px;
  }
`
export const GridRight = styled.div`
  flex-grow: 1;
`


export const TypeChooserTitle = styled.div`
  font-size: 72px;
  font-family: 'HKNova-Bold';
  letter-spacing: 0.1rem;
  font-weight: normal;
  margin-top: 0;
  line-height: 1;
  color: ${skyBlue};
  margin-bottom: 50px;
`

export const TypeChooserContain = styled.div`

  display: flex;
  padding: 0;
  padding-bottom: 40px;

  @media(max-width: ${breakpoints.m}px) {
    display: block;
  }

  .type-column {
    margin: 0;
    padding: 0;
  }

  .distinct-category {
    list-style-type: none;
    margin: 0;
    a {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
      margin: 30px 0 5px 0;
      color: ${oceanBlue};
      line-height: 1.4;
      display: block;
      padding: 4px 0;
      text-decoration: none;
      padding-left: 10px;

      &:has(.distinct-type + a.active) {
        background-color: ${skyBlue};
      }

      &.active {
        background-color: ${skyBlue};
        color: white;
      }
    }
  }

  .distinct-type {
    list-style-type: none;
    margin: 0;
    a {
      font-size: 14px;
      color: ${oceanBlue};
      font-family: 'HKNova-Regular';
      letter-spacing: 0.1rem;
      text-transform: unset;
      line-height: 1.4;
      display: block;
      padding: 4px 0;
      margin: 0;
      text-decoration: none;
      padding-left: 10px;
      &.active {
        background-color: white;
        color: black;
        text-decoration: underline;
      }
    }
  }

`;

export const CollectionItems = styled.div`
  display: flex;

  .item {
    margin-bottom: 30px;

    p {
      padding: 0 20px;
      text-align: center;
    }

  }
`;


export const CopperStripe = styled.div`
  background-image: url(${copperStripeJPG});
  width: 100%;
  height: ${props => props.customHeight ? `${props.customHeight}px` : "10px"};
  background-position: center;
  background-size: cover;
`;

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media(max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`
