import React from 'react'
import './styles.css'
import logo from '../../images/logo.png';
import { Link } from 'gatsby';
import FooterFormContain from './footerFormContain.js';
import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const FooterFormWrapper = styled.div`
   background-color: #152238;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 60px 0;
`

const FooterFormInterior = styled.div`
   background-color: #152238;
   display: flex;
   width: 100%;
   align-items: center;
   padding: 0 40px;
   justify-content: space-between;
   margin: auto;
   text-align: left;
   max-width: 1320px;

   @media (max-width: ${breakpoints.xl}px){
      max-width: 100%;
   }


   @media (max-width: ${breakpoints.l}px){
     flex-direction: column-reverse;
     text-align: center;
   }
`

const FooterFormWrap = styled.div`
   display: flex;
   justify-content: flex-start;
   padding: 0;
   max-width: 400px;
   color: white;

   @media (max-width: ${breakpoints.l}px){
      margin-top: 60px;
   }

   label {
      margin-bottom: 8px;
      display: block;
      font-size: 12px;
      font-family: 'HKNova-Regular';
      letter-spacing: 0.1rem;
      line-height: 1.4;
   }

   .form-header {
      margin-bottom: 50px;

      h1 {
         letter-spacing: 0.350rem;
         font-family: 'gilroyExtraBold';
         text-transform: uppercase;
         font-weight: normal;
         font-size: 14px;
         line-height: 1.7;
      }

      h3 {
         font-family: 'HKNova-Regular';
         letter-spacing: 0.1rem;
         font-size: 20px;
         line-height: 1.4;
      }
   }

   input[type="text"], input[type="email"] {
      background-color: #152238;
      border: 1px solid transparent;
      border-bottom: 1px solid #f9f9f9;
      color: white;
      font-size: 100%;
      font-family: 'HKNova-Regular';
      letter-spacing: 0.1rem;
      line-height: 1.4;
      padding-left: 1px;
      border-radius: 0;
      width: 100%;
      margin-bottom: 20px;
      padding: 4px 0;
   }

   input[type="submit"] {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.7;
      background: #4783b5;
      border-radius: 4px;
      line-height: 1em;
      padding: 14px 25px 15px 25px;
      text-transform: uppercase;
      color: whitesmoke;
      width: 100%;
      transition: color 300ms, background-color 300ms;
      cursor: pointer;
      -webkit-appearance: none;
      border: 1px solid rgba(255,255,255,0.5);
   }

   input[type="submit"]:hover {
      background: #4b8cc2;
      border: 1px solid white;
      color: white;
      transition: color 300ms, background-color 300ms;
   }

   input[type="submit"]:disabled {
      background: lightgrey;
      color: whitesmoke;
      border: 1px solid rgb(81, 76, 76);
      cursor: default;
      opacity: 1;
      -webkit-appearance: none;
   }

   input[type="submit"]:disabled:hover {
      color: whitesmoke;
      border: 1px solid rgb(81, 76, 76);
   }

`

const FooterInfoStyle = styled.div`
   color: white;
   letter-spacing: 0.350rem;
   font-family: 'gilroyExtraBold';
   text-transform: uppercase;
   font-weight: normal;
   font-size: 12px;
   line-height: 2;
   padding-right: 30px;

   & a {
      display: block;
      color: white;
      text-decoration: none;
   }

   @media (max-width: ${breakpoints.l}px){
      margin-top: 40px;
      text-align: center;
      padding-right: 0;
      & a {
         margin-top: 8px;
      }
   }

   .trademark {
      padding-bottom: 10px;
      border-bottom: 1px solid white;
      margin-bottom: 30px;
   }

   .extra-info {
      display: flex;

      @media (max-width: ${breakpoints.l}px){
         justify-content: center;
      }

      .social-link {
         margin-top: 10px;
      }
      .social-link-first {
         margin-right: 20px;
      }
   }
`

const FooterInfo = () => (
  <FooterInfoStyle>
    <Link to='/'><img width='200' src={logo} alt='Copper Compression Logo' /></Link>
    <Link to='/collections/all-1'>All Products</Link>
    <Link to='/benefits'>Benefits</Link>
    <Link to='/warranty'>Warranty</Link>
    <Link to='/contact'>Support</Link>
    <Link to='/blog'>Blog</Link>
    <Link to='/cart'>Cart</Link>
    <br />
    <Link to='/' className='trademark'>COPPERCOMPRESSION.COM™</Link>
   Follow Us
      <div className='extra-info'>
         <a className='social-link social-link-first' rel='noopener noreferrer' href='https://instagram.com/coppercompression' target='_blank'>
            <FontAwesomeIcon size="2x" icon={faInstagram} />
         </a>
         <a className='social-link' rel='noopener noreferrer' href='https://facebook.com/coppercompression' target='_blank'>
            <FontAwesomeIcon size="2x" icon={faFacebook} />
         </a>
      </div>
  </FooterInfoStyle>
)

const FooterForm = () => (
  <FooterFormWrapper>
    <FooterFormInterior>
      <FooterFormWrap>
         <FooterFormContain title="Sign up for 20% Off" title2="Become an insider for exclusive product updates, joint health tips, and 20% off your first order!" submit="Sign me up & Save 20%" source="footer-form" isFooter={true} />
      </FooterFormWrap>
      <FooterInfo />
    </FooterFormInterior>
  </FooterFormWrapper>
)

export default FooterForm
