import React from 'react'
import { Link } from 'gatsby';
import { SiteFooterStyle, GridLeft, GridRight } from './styles'

const SiteFooter = () => {
  return (
    <SiteFooterStyle>
      <GridLeft>
        <p>COPPERCOMPRESSION.COM – © { new Date().getFullYear() } Copper Compression</p>
      </GridLeft>
      <GridRight>
        <Link to="/terms-and-conditions">
          Terms & Conditions
        </Link>
        <Link to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link to="/sources-and-references">
          Sources & References
        </Link>
      </GridRight>
    </SiteFooterStyle>
  )
}

export default SiteFooter
