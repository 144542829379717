import { useContext, useEffect } from 'react';
import StoreContext from '../../context/StoreContext';

/**
 * JSX-less component that adds effects to handle query string parameters.
 */
export const QueryStringUtilities = () => {
  const context = useContext(StoreContext);

  // `discount` - automaticly appling discount codes
  useEffect(addDiscountCodeToCheckoutFromQueryString(context), [context.checkout.id]);

  // Return null instead of JSX.
  return null;
}

/**
 * Checks to see if there is a `discount` query parameter. If there is, then it
 * is added to the checkout session. This WILL override any existing discount
 * code in the checkout already.
 *
 * @param {StoreContext} context
 *
 * @return {Function} effect
 */
const addDiscountCodeToCheckoutFromQueryString = (context) => {
  return () => {
    const { client, checkout, addDiscountCode, activeDiscountCode } = context;

    const currentDiscountCode = activeDiscountCode(checkout);
    if (window && checkout.id) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const discountCode = urlParams.get('discount');

      // Only apply if the code is different than given.
      if (currentDiscountCode !== discountCode) {
        addDiscountCode(client, checkout.id, discountCode);
      }
    }
  }
}
