import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import Hit from './Hit';
import allowedCollections from './hardcoded-collections';

const Hits = ({ hits, deactivate, type, max }) => {
  let counter = 0;
  return hits.map((hit, index) => {
    if (max && counter >= max) {
      return null;
    }

    // If the type we are working on is a collection, then we want to filter
    // based on a hardcoded list.
    if (type === 'collections') {
      const { title } = hit;
      if (typeof allowedCollections[title.toLowerCase()] === 'undefined') {
        return null;
      }
    }

    counter++;
    return <Hit key={index} hit={hit} deactivate={deactivate} type={type} />;
  })
}

export default connectHits(Hits);