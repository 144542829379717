import React from 'react';
import { Link } from 'gatsby';
import { faShareAlt, faCircle, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import { Price } from '../Price';
import { Result, PriceTag, Icon, Title, ResultLeft, Image, ResultImage } from './styles';

const Hit = ({ hit, deactivate, type }) => {
  const { handle, title, image: img } = hit;
  const filteredTitle = type === 'articles' ? title : title.replace('Copper Compression','');

  let image;
  if (img) {
    image = <Image fixed={{
      width: 25,
      height: 25,
      src: img,
      srcSet: img,
    }}/>;
  }

  let icon;
  switch (type) {
    case 'collections':
      icon = faShareAlt;
      image = null;
      break;
    case 'articles':
      icon = faCommentAlt;
      type = 'blog';
      break;
    case 'products':
    default:
        icon = faCircle;
  }

  return (
    <Link to={`/${type}/${handle}/`} onClick={() => deactivate()}>
      <Result>
        <ResultLeft>
          <ResultImage>
            {image ? image : <Icon buffer={type === 'blog' ? '2px' : '0px'} icon={icon} />}
          </ResultImage>
          <Title>
            { filteredTitle }
          </Title>
        </ResultLeft>
        {type === 'products' &&
          <PriceTag>
            <Price variant={hit} tags={[]}/>
          </PriceTag>
        }
      </Result>
    </Link>
  );
}

export default Hit;