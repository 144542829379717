import React, { useContext, useState, useEffect } from 'react'
import { globalHistory } from '@reach/router';
import StoreContext from '../../context/StoreContext'
import {CopperStripe} from '../../utils/styles';
import Logo from '../../images/logo.png';
import CollectionBar from '../../components/CollectionBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpen, faShoppingCart, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { CloseIcon } from '../../images/icons/close'
import { HamburgerIcon } from '../../images/icons/hamburger'
import badCodes from '../../utils/badCodes'

import {
  Wrapper,
  Container,
  CartCounter,
  MenuItem,
  MenuLink,
  MobileMenuLink,
  MobileNav
} from './styles'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  });

  return quantity
}

const DiscountCodeDisplay = ({discountCodes, checkout}) => {
  if (discountCodes && discountCodes.length > 0) {

      const discountClasses = checkout.discountApplications[0] && badCodes.includes(discountCodes) ? "coupon-code coupon-code-expired":"coupon-code";

      return (
        <span className={discountClasses}>
          <FontAwesomeIcon icon={faTicketAlt} />
          {discountCodes}</span>
      )
  } else {
    return false;
  }
}


const Navigation = ({showProductsAsActive}) => {

  const context = useContext(StoreContext)

  const { checkout, activeDiscountCode } = context

  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))

  const [discountCodes, setDiscountCodes] = useState([]);

  useEffect(() => setDiscountCodes(activeDiscountCode(checkout)), [checkout, activeDiscountCode]);

  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  useEffect(() => {
    if (mobileNavOpen) {
      document.body.classList.add("nav-open");
    } else {
      document.body.classList.remove("nav-open");
    }
  }, [mobileNavOpen]);

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  // Close the mobile menu if sent to any other page.
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && mobileNavOpen) {
        setMobileNavOpen(false);
      }
    })
  }, [mobileNavOpen, setMobileNavOpen])


  return (
    <>
      <Wrapper>
        <Container>
          <MenuLink className='logo-link-mobile' to='/' onClick={() => setMobileNavOpen(false)}>
            <img src={ Logo } alt='Copper Compression Logo' width="72" />
          </MenuLink>

          <MenuLink className='logo-link' to='/'>
            <img src={ Logo } alt='Copper Compression Logo' width="200" />
          </MenuLink>

          <MenuItem>
            <MenuLink
              className={showProductsAsActive ? 'active' : ''}
              activeClassName="active"
              to="/collections/all-1"
            >
              Products
            </MenuLink>
          </MenuItem>
          <MenuLink activeClassName="active" to="/drew-brees/">
            Drew Brees
          </MenuLink>
          <MenuLink activeClassName="active" to="/benefits/">
            Benefits
          </MenuLink>
          <MenuLink activeClassName="active" to="/warranty/">
            Warranty
          </MenuLink>
          <MenuLink activeClassName="active" to="/contact/">
            Support
          </MenuLink>

          <MenuLink
            activeClassName="active"
            className={ quantity !== 0 ? 'cart-link cart-link-hasCart' : 'cart-link'}
            to='/cart/'
          >

            <DiscountCodeDisplay discountCodes={discountCodes} checkout={checkout} />

            Cart
            <FontAwesomeIcon size="2x" icon={faShoppingCart} />
            { quantity !== 0 &&
              <CartCounter className='cart-counter'>
                { quantity }
              </CartCounter>
            }
          </MenuLink>

          { quantity !== 0 &&
            <MenuLink
              activeClassName="active"
              className='cart-link-mobile cart-link-hasCart'
              to='/cart/'
            >
              <FontAwesomeIcon size="2x" icon={faShoppingCart} />
              <CartCounter className='cart-counter'>
                { quantity }
              </CartCounter>
            </MenuLink>
          }

          <MenuLink className="hamburger-icon" to='/' onClick={(event) => { event.preventDefault(); setMobileNavOpen(!mobileNavOpen)}}>
            {!mobileNavOpen && <HamburgerIcon /> }
            {mobileNavOpen && <CloseIcon /> }
          </MenuLink>

          <MobileNav className={mobileNavOpen ? 'mobile-nav__open' : 'mobile-nav__closed'}>
            <div className='mobile-nav-top'>
              <MobileMenuLink activeClassName="active" onClick={() => setMobileNavOpen(false)} to="/collections/all-1" className="product-link">
                Products
              </MobileMenuLink>
              <MobileMenuLink activeClassName="active" to="/drew-brees/">
                Drew Brees
              </MobileMenuLink>
              <MobileMenuLink activeClassName="active" onClick={() => setMobileNavOpen(false)} to="/benefits">
                Benefits
              </MobileMenuLink>
              <MobileMenuLink activeClassName="active" onClick={() => setMobileNavOpen(false)} to="/warranty">
                Warranty
              </MobileMenuLink>
              <MobileMenuLink activeClassName="active" onClick={() => setMobileNavOpen(false)} to="/contact">
                Support
              </MobileMenuLink>
              <MobileMenuLink activeClassName="active" onClick={() => setMobileNavOpen(false)} className="cart-link" to='/cart'>
                Cart
              </MobileMenuLink>
            </div>

            <div className='extra-info'>
              <a className='support-link' rel='noopener noreferrer' href='mailto:help@coppercompression.com' target='_blank'>
                <FontAwesomeIcon size="lg" icon={faEnvelopeOpen} />
                help@coppercompression.com
              </a>
              <a className='social-link' rel='noopener noreferrer' href='https://facebook.com/coppercompression' target='_blank'>
                <FontAwesomeIcon size="lg" icon={faFacebook} />Copper Compression
              </a>
              <a className='social-link' rel='noopener noreferrer' href='https://instagram.com/coppercompression' target='_blank'>
                <FontAwesomeIcon size="lg" icon={faInstagram} />@coppercompression
              </a>
            </div>

          </MobileNav>
        </Container>
        <CopperStripe customHeight="8" />
        <CollectionBar mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
      </Wrapper >
    </ >
)
}

export default Navigation
