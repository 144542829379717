import React from 'react'

export const CloseIcon = () => 

    <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-40.000000, -232.000000)">
                <g id="close" transform="translate(20.000000, 230.000000)">
                    <rect id="Rectangle" x="0" y="0" width="200" height="164"></rect>
                    <rect id="line" fill="#DF9978" transform="translate(100.000000, 82.000000) rotate(45.000000) translate(-100.000000, -82.000000) " x="0" y="67" width="200" height="30" rx="4"></rect>
                    <rect id="line" fill="#DF9978" transform="translate(100.000000, 82.000000) rotate(-45.000000) translate(-100.000000, -82.000000) " x="0" y="67" width="200" height="30" rx="4"></rect>
                </g>
            </g>
        </g>
    </svg>

