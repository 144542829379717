import React from 'react'

export const HamburgerIcon = () => 

    <svg width="200px" height="146px" viewBox="0 0 200 146" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-120.000000, -45.000000)">
                <g id="hamburger" transform="translate(120.000000, 36.000000)">
                    <rect id="Rectangle" x="0" y="0" width="200" height="164"></rect>
                    <rect id="line" fill="#DF9978" x="0" y="9" width="200" height="30" rx="4"></rect>
                    <rect id="line" fill="#DF9978" x="0" y="67" width="200" height="30" rx="4"></rect>
                    <rect id="line" fill="#DF9978" x="0" y="125" width="200" height="30" rx="4"></rect>
                </g>
            </g>
        </g>
    </svg>
