import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { oceanBlue, skyBlue, copper } from '../../constants/theme';

import { breakpoints } from '../../utils/styles'

export const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${breakpoints.l}px){
    display: block;
  }
`

export const Wrapper = styled.div`
  background: ${oceanBlue};
  font-family: 'gilroyExtraBold';
  font-weight: normal;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
  position: fixed;
  width: 100%;
  z-index: 10000;
  top: 0;
  @media (max-width: ${breakpoints.l}px){
    position: absolute;
  }
`

export const MobileNav = styled.div`

  position: absolute;
  box-sizing: border-box;
  top: 78px;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: white;
  color: ${skyBlue};


  &.mobile-nav__open {
    transition: left 250ms ease-out 20ms;
    left: 0;
  }

  &.mobile-nav__closed {
    transition: left 250ms ease-out 20ms, visibility 0ms linear 270ms, width 0ms linear 270ms;
    left: 100vw;
    visibility: hidden;
    width: 0;
  }

  .mobile-nav-top {
    display: flex;
    flex-direction: column;
    padding: 21px 0 40px 23px;
  }

  .extra-info {
    font-family: 'HKNova-SemiBold';
    letter-spacing: 0;
    padding: 21px;
    font-size: 12px;
    a {
      .svg-inline--fa {
        margin-right: 14px;
        color: ${skyBlue};
      }
      margin-bottom: 20px;
      display: block;
      color: ${skyBlue};
      text-decoration: none;
    }
  }

`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 0 0 30px;
  max-width: 1320px;
  justify-content: space-between;

  @media (max-width: ${breakpoints.l}px){
    justify-content: flex-end;
    padding: 0 0 0 12px;
  }
`

export const MobileMenuLink = styled(Link)`
  color: ${skyBlue};
  text-decoration: none;
  font-size: 14px;
  padding: 0;
  height: 70px;
  margin: 0;
  line-height: 70px;
  transition: background-color 400ms;
  position: relative;
`

export const MenuItem = styled.span`
  @media (max-width: ${breakpoints.l}px){
    display: none;
  }

  a {
    display: block;
  }
`

export const MenuLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 12px;
  padding: 0 20px;
  height: 70px;
  margin: 0;
  line-height: 70px;
  transition: background-color 400ms;
  position: relative;

  &:hover {
      color: ${copper};
  }

  @media (max-width: ${breakpoints.xl}px){
    font-size: 11px;
    padding: 0 10px;
  }

  @media (max-width: ${breakpoints.l}px){
    display: none;
  }

  &.product-link {
    color: white;
  }

  span.coupon-code {
    background-color: ${copper};
    border-radius: 20px;
    padding: 4px;
    margin-right: 24px;
    color: white;
    position: relative;

    &.coupon-code-expired {
      display: none;
      opacity: 0.50;
      text-decoration: line-through;
    }

    svg {
      transform: translateY(0px);
      margin-right: 10px;
    }
  }

  &.logo-link {
    padding: 0;
    padding-top: 8px;
    height: calc(70px - 13px);

    @media (max-width: ${breakpoints.xl}px){
      img {
        width: 120px;
      }
    }
  }

  &.logo-link-mobile {

    display: none;

    @media (max-width: ${breakpoints.l}px){
      margin-right: auto;
      display: block;
      width: 140px;
      padding: 0;
      img {
        margin-top: 17px;
        width: 140px;
      }
    }

  }

  &.hamburger-icon {

    display: none;
    transform: translateY(-20px);

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: ${breakpoints.l}px){
      width: 32px;
      height: 32px;
      display: block;
      color: ${copper};
      font-size: 48px;

    }
  }

  &.cart-spacer-mobile {
    flex-grow: 1;
  }

  &.cart-link-mobile {
    display: none;

    color: ${copper};
    padding-left: 10px;
    padding-right: 18px;
    padding-top: 5px;
    box-sizing: border-box;
    height: 70px;
    overflow: hidden;

    justify-self: end;

    .cart-counter {
      color: white;
      background-color: ${skyBlue};
    }

    &.active {
      color: white;
      .cart-counter {
        color: white;
        background-color: ${copper};
      }
    }


    @media (max-width: ${breakpoints.l}px){
      display: block;
    }
  }

  &.cart-link {
    color: ${copper};
    padding-left: 30px;
    padding-right: 18px;
    box-sizing: border-box;
    height: 70px;
    overflow: hidden;
    position: relative;


    svg {
      transform: translateY(4px);
      margin-left: 10px;
    }


    @media (max-width: ${breakpoints.l}px){
      display: none;
    }

    transition: background-color 200ms, color 200ms;


    .cart-counter {
      color: white;
      background-color: ${skyBlue};
    }


    &:hover {
      transition: color 200ms;

      color: white;
      .cart-counter {
        transition: background-color 200ms;
        background-color: ${copper};
      }
    }

    &.active {
      color: white;
      .cart-counter {
        background-color: ${copper};
      }
    }

  }

  &.active {

    background-color: ${skyBlue};

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      bottom: -8px;
      left: 0;
      background-color: ${skyBlue};
      z-index: 100;
    }

    &:hover {
      color: white;
    }
  }

  &.product-link:hover {
    background-color: ${skyBlue};
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      bottom: -8px;
      left: 0;
      background-color: ${skyBlue};
      z-index: 100;
    }
  }
`

export const CartCounter = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  background-color: white;
  position: absolute;
  border-radius: 100%;
  line-height: 18px;
  width: 18px;
  height: 18px;
  right: 8px;
  top: 20px;
`
