/**
 * Hardcoded map of discount code/price rule titles to an array of product
 * handles.
 */
export default {
    'GETGAITER': [
        'copper-compression-protective-neck-gaiter'
    ],
    'VIP40': [
        'copper-infused-face-mask',
        'copper-compression-copper-infused-face-mask-youth-sized',
        'knee-sleeve-1',
        'copper-compression-protective-neck-gaiter',
        'copper-compression-posture-corrector-for-men-and-women-guaranteed-highest-copper-back-brace-posture-support-correctors-adjustable-straightener-supports-shoulder-and-upper-back-for-correct-posture',
        'copper-compression-wrist-brace-guaranteed-highest-copper-content-support-for-wrists-carpal-tunnel-arthritis-tendonitis-night-and-day-wrist-splint-for-men-and-women-fit-for-right-and-left-hand',
        'copper-infused-face-mask-aws',
        'copper-compression-elbow-sleeve',
        'copper-compression-recovery-foot-sleeves-plantar-fasciitis-support-socks',
        'copper-compression-arthritis-full-finger-recovery-gloves',
        'copper-compression-calf-shin-splint-recovery-leg-sleeves',
        'copper-compression-knee-high-recovery-support-socks',
        'bunion-relief-kit',
        'arch-support-brace-sleeve',
        'copy-of-plantar-fasciitis-night-sock',
        'copper-compression-recovery-thumb-brace',
        'copper-compression-finger-splint-medical-grade-aluminum-brace-support-guard-splints-for-straightening-broken-fingers-injuries-arthritis-trigger-finger-adjustable-knuckle-immobilizer-braces',
        'copper-compression-metatarsal-pads-for-women-and-men-guaranteed-highest-copper-foot-pads-for-ball-of-feet-gel-ball-of-foot-cushions-for-mortons-neuroma-sesamoiditis-metatarsul-incapsulitus-callus',
        'copper-compression-extra-support-knee-brace-highest-copper-content-guaranteed-best-adjustable-copper-infused-fit-knee-brace-open-patella-stabilizer-neoprene-sleeve-for-sprains-and-injury-recovery',
        'copper-compression-full-leg-sleeve-guaranteed-highest-copper-sleeves-pants-single-leg-pant-tights-fit-for-men-and-women-copper-knee-brace-thigh-calf-support-socks-basketball-arthritis',
        'copper-compression-groin-thigh-sleeve-and-hip-support-wrap-adjustable-neoprene-brace-for-hamstring-quad-pulled-muscles-lower-back-sciatica-nerve-hip-flexor-strain-arthritis-for-men-and-women',
        'copper-compression-pure-copper-bracelet-for-arthritis-99-9-pure-copper-magnetic-therapy-12-magnet-bangle-bracelet-for-men-women-therapeutic-bracelets-for-carpal-tunnel-rsi-joint-pain-golf',
    ]
};