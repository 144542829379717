import React, { useContext } from 'react';
import StoreContext from '../../context/StoreContext';
import rules from './hardcoded-rules';
import badCodes from '../../utils/badCodes'

export const Price = ({ variant, tags, TagWrapper = 'div', TagInner = 'span' }) => {
  const { checkout } = useContext(StoreContext);
  const skipDiscount = (tags.find(tag => tag === 'No Discount') || []).length > 0;

  const getPrice = price => Intl.NumberFormat(undefined, {
    currency: checkout.currencyCode ? checkout.currencyCode : 'USD',
    style: 'currency',
    // If the price has decimal points, display 2 precision digits, otherwise
    // if it is a whole number, just display the whole number.
    minimumFractionDigits: (price % 1 !== 0) ? 2 : 0,
  }).format(parseFloat(price ? price : 0));

  try {
    // Calculate the percentage to use. If there is no percentage, and we do
    // not have a compare at price to display, then we bail out and only show
    // one value.
    let percent;

    if (checkout.discountApplications && checkout.discountApplications.length > 0) {
      
      percent = checkout.discountApplications[0].value.percentage;

      // If the discount is not per line item, bail.
      if (checkout.discountApplications[0].targetType !== 'LINE_ITEM') {
        throw new Error('Wrong kind of discount');
      }

      // If the discount is not allowed, bail, UNLESS there is also a
      // compare at price.
      if (badCodes.includes(checkout.discountApplications[0].code)) {
        if (!variant.compareAtPrice || skipDiscount) {
          throw new Error('Discount is not applicable (expired)');
        }

        // if discount code is not allowed but there is a compare at price set % to 0
        percent = 0;

      }

      // Get products from the hardcoded list of rules we have defined. If
      // there is a key set, then we only will apply it to the products that
      // are also set in the list.
      const products = rules[checkout.discountApplications[0].code];
      if (products) {
        if (products.length > 0) {
          // Get the handle from the data within the variant
          if (!products.includes(variant.product.handle)) {
            throw new Error('Hardcoded rule, product is not in array');
          }
        } else {
          throw new Error('Hardcoded rule created, but no products set');
        }
      }
    } else {
      if (!variant.compareAtPrice) {
        throw new Error('Nothing to do');
      }
    }

    // Calculate the price and the discount price.
    let discountPrice = variant.price;
    let price = variant.price;

    if (percent && variant.compareAtPrice === '0.00') {
      discountPrice = variant.price - (variant.price * (percent/100));
    }

    // If the variant has a compare at price, that is what we need to set
    // the original price to.
    if (variant.compareAtPrice && variant.compareAtPrice !== '0.00') {
      price = variant.compareAtPrice;

      // If the variant has a compare at price, but is not eligible to have
      // a discount, then we set the discount price to the variant price.
      if (skipDiscount) {
        discountPrice = variant.price;
      }
    }

    // If the discounted price turns out to be the exact same price, then we
    // want to bail out and show the standard price tag for this product.
    if (parseFloat(price) === parseFloat(discountPrice)) {
      throw new Error('Discount is the same price');
    }

    return (
      <TagWrapper>
        { getPrice(discountPrice) }
        <TagInner>{ getPrice(price) }</TagInner>
      </TagWrapper>
    );
  } catch (e) {
    return (
      <TagWrapper>{ getPrice(variant.price) }</TagWrapper>
    );
  }
}
