import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import StoreContext, { defaultStoreContext } from '../context/StoreContext'
import { GlobalStyle, CopperStripe } from '../utils/styles'
import Navigation from '../components/Navigation'
import FooterForm from '../components/FooterForm'
import SiteFooter from '../components/SiteFooter'
import FSB from '../components/FSB'
import snapTrack from '../utils/snapTrack'
import ireTrack from '../utils/ireTrack'
import { QueryStringUtilities } from '../components/QueryStringUtilities'

const Wrapper = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white;
`

class Layout extends React.Component {
  state = {
    store: {
      ...defaultStoreContext,
      addVariantToCart: (variantId, quantity) => {
        if (variantId === '' || !quantity) {
          console.error('Both a size and quantity are required.')
          return
        }

        this.setState(state => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))

        const { checkout, client } = this.state.store
        const checkoutId = checkout.id
        const lineItemsToUpdate = [
          { variantId, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .addLineItems(checkoutId, lineItemsToUpdate)
          .then(checkout => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout,
                adding: false,
              },
            }))
          })
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        return client.checkout
          .removeLineItems(checkoutID, [lineItemID])
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [
          { id: lineItemID, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .updateLineItems(checkoutID, lineItemsToUpdate)
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
      addDiscountCode: (client, checkoutID, code) => {
        if (code) {
          return client.checkout
            .addDiscount(checkoutID, code)
            .then(res => {
              this.setState(state => ({
                store: {
                  ...state.store,
                  checkout: res,
                }
            }))
          })
        }
      },
      activeDiscountCode: (checkout) => {
        if (checkout && checkout.discountApplications) {
          const codes = checkout.discountApplications.map(discount => discount.code);
          if (codes.length > 0) {
            return codes[0];
          }
        }

        return false;
      },
    },
  }

  async initializeCheckout() {
    // Check for an existing cart.
    const isBrowser = typeof window !== 'undefined'
    const existingCheckoutID = isBrowser
      ? localStorage.getItem('shopify_checkout_id')
      : null

    const setCheckoutInState = checkout => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', checkout.id)
      }

      this.setState(state => ({
        store: {
          ...state.store,
          checkout,
        },
      }))
    }

    const createNewCheckout = () => this.state.store.client.checkout.create()
    const fetchCheckout = id => this.state.store.client.checkout.fetch(id)

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID)
        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          setCheckoutInState(checkout)
          return
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null)
      }
    }

    const newCheckout = await createNewCheckout()
    setCheckoutInState(newCheckout)
  }


  async initializeClickId() {
    // Check for an existing clickId.
    const isBrowser = typeof window !== 'undefined'
    const existingClickID = isBrowser
      ? localStorage.getItem('impact_click_id')
      : null

    if (!existingClickID && isBrowser) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const clickID = urlParams.get('irclickid');

      if (clickID) {
        localStorage.setItem('impact_click_id', clickID)
      }

    }

  }


  componentDidMount() {
    this.initializeCheckout();
    this.initializeClickId();
    snapTrack('PAGE_VIEW');
    ireTrack('identify');
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'optimize.activate' })
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.path !== prevProps.path) {
      this.initializeClickId();
      snapTrack('PAGE_VIEW');
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: 'optimize.activate' })
    }
  }

  render() {
    const { children, path } = this.props

    const showFooterForm = !path.includes('warranty');

    let isProductPage = !['/benefits/', '/warranty/', '/contact/', '/cart/', '/drew-brees/'].includes(path);

    return (
      <StoreContext.Provider value={ this.state.store }>
        <QueryStringUtilities />
        <GlobalStyle />
        <StaticQuery
          query={ graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={ data => (
            <>
              <Navigation siteTitle={ data.site.siteMetadata.title } showProductsAsActive={isProductPage}/>
              <FSB />
              <Wrapper>
                { children }
                <FooterForm showForm={showFooterForm} />
                <CopperStripe />
                <SiteFooter />
              </Wrapper>
            </>
          ) }
        />
      </StoreContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
