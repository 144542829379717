export default function snapTrack(eventType, options) {
  if (process.env.NODE_ENV === `production` && typeof window !== "undefined") {
    if (window.snaptr != null) {
      if (options) {
        window.snaptr('track', eventType, options);
      } else {
        window.snaptr('track', eventType);
      }
    }
  }
}


//  snaptr('track','VIEW_CONTENT',{
//    'item_category':parentVariant
//  })

// snaptr('track','PAGE_VIEW'); snapTrack('PAGE_VIEW', null);
// snaptr('track','VIEW_CONTENT',{'item_category':pxItemCat});
// snaptr('track','ADD_CART',{'item_category':pxItemCat});

// window.addEventListener('DOMContentLoaded', function(){
//   if (window.location.href.indexOf("/products/") > -1) {
//     var pxItemCat = document.getElementsByClassName('add-to-cart')[0].parentElement.parentElement.previousElementSibling.childNodes[1].getAttribute('data-product-id');
//     snaptr('track','VIEW_CONTENT',{
//       'item_category':pxItemCat
//     })
//     var pxAcBtn = document.querySelector('button.add-to-cart');
//     if(pxAcBtn){
//       pxAcBtn.addEventListener('click',fnAddCart);
//     }
//   }
// });

// function fnAddCart(){
//   snaptr('track','ADD_CART',{
//     'item_category':pxItemCat
//   });
// }
