import React, { useContext } from 'react'
import { Link } from 'gatsby'
import StoreContext from '../../context/StoreContext'

const FSB = () => {
  const context = useContext(StoreContext)
  const { checkout } = context
  const totalPrice = checkout.totalPrice ? checkout.totalPrice : '0';
  const fsGap = 50 - totalPrice;
  const fsGapRounded = fsGap.toFixed(0);
  
  const fsReady = checkout.totalPrice ? true: false;

  const fsbStyle = {
    display: 'block',
    marginTop: '174px',
    color: 'white',
    backgroundColor: '#152238',
    textDecoration: 'none',
    fontSize: '16px',
    textAlign: 'center',
    padding: '9px',
    minHeight: '19px'
  }

  return (
      <Link to='/cart' style={fsbStyle} className='fsb'>
        { fsReady && fsGap === 50 && `Free Shipping on orders over $50!` }
        { fsReady && fsGap < 50 && fsGap > 0 && `Only $${fsGapRounded} to go for free shipping!` }
        { fsReady && fsGap <= 0 && `Your cart qualifies for free shipping!` }
      </Link>
  )

}

export default FSB
