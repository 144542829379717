import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '../../utils/styles'
import { skyBlue, oceanBlue, copper } from '../../constants/theme'

export const Wrapper = styled.div`
  border-bottom: 1px solid ${skyBlue};
  border-bottom-color: ${props => props.currentonpage ? 'transparent' : skyBlue};
  background-color: ${skyBlue};

  background-color: ${props => props.currentonpage ? 'rgba(71,131,181,0.10)' : skyBlue};

  height: 95px;
  position: ${props => props.currentonpage ? 'relative' : 'fixed'};

  top: ${props => props.currentonpage ? '0px' : '78px'};

  z-index: 200;
  left: 0;
  width: 100%;
  @media (max-width: ${breakpoints.l}px){
    position: ${props => props.currentonpage ? 'relative' : 'absolute'};
  }
`

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  top: 150px;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => props.active ? 'block' : 'none'};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 auto;
  max-width: 1320px;
  padding: ${props => props.search ? '0' : '20px'};
  box-sizing: border-box;

  @media (max-width: ${breakpoints.s}px){
    padding: ${props => props.search ? '0' : '20px 14px 0 14px'};
  }
`

export const CollectionLink = styled(Link)`

  color: ${props => props.currentonpage ? oceanBlue : 'white'};
  text-decoration: none;
  font-size: ${props => props.currentonpage ? '14px' : '11px'};

  margin: 0 10px;
  text-align: center;
  line-height: 18px;
  cursor: pointer;

  @media (max-width: ${breakpoints.l}px){
    letter-spacing: .175rem;
  }

  @media (max-width: ${breakpoints.m}px){
    font-size: 9px;
    margin: 0 5px;
  }

  @media (max-width: ${breakpoints.m}px){
    font-size: 7px;
    margin: 0 3px;
  }

  &.desktop-only {
    @media (max-width: ${breakpoints.m}px){
      display: none;
    }
  }

  &.mobile-only {
    @media (min-width: ${breakpoints.m}px){
      display: none;
    }
  }

  &.collection-link-All, &.collection-link-Best {
    display: ${props => props.currentonpage ? 'none' : 'block'};

    @media (max-width: ${breakpoints.s}px){
      display: none;
    }
  }

  &.collection-link-Apparel {
    display: ${props => props.currentonpage ? 'none' : 'block'};

    @media (max-width: ${breakpoints.xs}px){
      display: none;
    }
  }

  &.collection-link-Face {
    img {
      width: 32px;

      @media (max-width: ${breakpoints.s}px){
        width: 27px;
      }
    }
  }


  &.collection-link-Bundles {
    img {
      width: 56px;

      @media (max-width: ${breakpoints.s}px){
        width: 27px;
      }
    }
  }



  &.collection-link-Apparel {
    img {
      width: 32px;

      @media (max-width: ${breakpoints.s}px){
        width: 27px;
      }
    }
  }

  &.active {
    color: ${copper};
    img {
      filter: hue-rotate(220deg) invert(80%);
    }
  }

  &:hover {
    img, svg {
      transition: transform 200ms;
      transform: translateY(-3px);
      @media (max-width: ${breakpoints.s}px){
        transform: translateY(-1px);
      }
    }
  }

  img, svg {
    width: 30px;
    display: block;
    margin: auto;
    margin-bottom: 5px;
    max-height: 32px;
    object-fit: contain;

    filter: ${props => props.currentonpage ? 'invert(0%)' : 'invert(100%)'};

    @media (max-width: ${breakpoints.s}px){
      width: 22px;
    }
  }

  svg {
    filter: none;
    margin-bottom: 10px;
    font-size: 1.25rem;
  }
`
