import React, { useEffect, useState, useCallback } from 'react'
import { globalHistory } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import SearchBar from '../Search/SearchBar';

import {
  Wrapper,
  Container,
  CollectionLink,
  Overlay
} from './styles'

const collections = [
  // {
  //   handle: "all-1",
  //   id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NDczNzc3MDUzOA==",
  //   image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/all_25572d1f-687d-488e-aad0-63edfe0166e2.png?v=1572447153"},
  //   title: "All"
  // },
  {
    handle: "best-sellers-collections",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0Mzg1NzA5Mw==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/best-selling.png?v=1575931504"},
    title: "Best",
    mobileOnly: false,
    desktopOnly: false
  },
  {
    handle: "health",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE3MzAwMjI2MDUyMg==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/safety.png?v=1585081283"},
    title: "Face",
    mobileOnly: false,
    desktopOnly: false
  },
  {
    handle: "hands",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0ODg5MjEwMQ==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/hand.png?v=1571946584"},
    title: "Hands",
    mobileOnly: false,
    desktopOnly: true
  },
  {
    handle: "arms",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0ODg5MTkwOQ==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/arm.png?v=1571946623"},
    title: "Arms",
    mobileOnly: false,
    desktopOnly: true
  },
  {
    handle: "feet",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0ODg5MjM1Nw==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/feet.png?v=1571946987"},
    title: "Feet",
    mobileOnly: false,
    desktopOnly: true
  },
  {
    handle: "legs",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0ODg5MjAzNw==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/legs.png?v=1571944448"},
    title: "Legs",
    mobileOnly: false,
    desktopOnly: true
  },
  {
    handle: "hands-feet",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MTA0MzQ4NjkwMA==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/hands_and_feet.png?v=1614791633"},
    title: "H&F",
    mobileOnly: true,
    desktopOnly: false
  },
  {
    handle: "back",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0ODg5MjYxMw==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/back.png?v=1571946937"},
    title: "Back",
    mobileOnly: false,
    desktopOnly: true
  },
  {
    handle: "upper-body",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MTA0MTQyMjUxNg==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/upper_body.png?v=1614791667"},
    title: "Upper",
    mobileOnly: true,
    desktopOnly: false
  },
  // {
  //   handle: "apparel",
  //   id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE2NjQ1NzcwNDQ5MA==",
  //   image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/apparel.png?v=1576089298"},
  //   title: "Apparel"
  // },
  {
    handle: "lower-body",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MTA0MjYzNDkzMg==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/lower_body.png?v=1614791609"},
    title: "Lower",
    mobileOnly: true,
    desktopOnly: false
  },
  {
    handle: "bundles",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI0NDgwMDk0NjM1Ng==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/Artboard_1.png?v=1614615951"},
    title: "Bundles",
    mobileOnly: false,
    desktopOnly: false
  },
  {
    handle: "sale",
    id: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MDQzOTc2OTI2OA==",
    image: {src: "https://cdn.shopify.com/s/files/1/0992/9284/collections/SALE-ICON-02.png?v=1614182494"},
    title: "Sale",
    mobileOnly: false,
    desktopOnly: false
  }
]

const CollectionBar = ({onPage, mobileNavOpen, setMobileNavOpen}) => {
  const [isSearchBar, setIsSearchBar] = useState(false);
  const searchRef = React.createRef();

  const toggleSearchBar = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }

    const state = !isSearchBar;
    if (mobileNavOpen && state) {
      setMobileNavOpen(false);
    }

    setIsSearchBar(state);
  }, [isSearchBar, mobileNavOpen, setMobileNavOpen]);

  const keyListener = useCallback((event) => {
    // If we hit the escape key, and the search bar is open...close it.
    if (event.keyCode === 27 && isSearchBar) {
      toggleSearchBar();
    }
  }, [isSearchBar, toggleSearchBar]);

  useEffect(() => {
    document.addEventListener("keydown", keyListener, false);
    return () => {
      document.removeEventListener("keydown", keyListener, false);
    };
  }, [isSearchBar, keyListener]);

  const clickedOverlay = () => {
    if (isSearchBar) {
      toggleSearchBar();
    }
  };

  useEffect(() => {
    if (isSearchBar) {
      disableBodyScroll(searchRef.current);
    } else {
      clearAllBodyScrollLocks();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [searchRef, isSearchBar]);

  // Turn off search if it is open and the nav bar is opened.
  useEffect(() => {
    if (mobileNavOpen && isSearchBar) {
      toggleSearchBar();
    }
  }, [mobileNavOpen, isSearchBar, toggleSearchBar])

  // Close the search bar if sent to any other page.
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && isSearchBar) {
        toggleSearchBar();
      }
    })
  }, [isSearchBar, toggleSearchBar])

  return (
    <>
      <Overlay active={isSearchBar} onClick={clickedOverlay}/>
      <Wrapper currentonpage={onPage}>
        <Container search={isSearchBar}>
          {isSearchBar && <SearchBar deactivate={toggleSearchBar} ref={searchRef} />}
          {!isSearchBar &&
            <>
              {collections.map(({ id, title, handle, image, mobileOnly, desktopOnly }) => (
                <CollectionLink currentonpage={onPage} key={ id } className={`${desktopOnly ? 'desktop-only': ''} ${mobileOnly ? 'mobile-only': ''} collection-link-${title}`} activeClassName="active" to={ `/collections/${handle}` }>
                  { image ? <img style={ { minHeight: '32px', display: 'block' } } src={ image.src } alt='collection' /> : "" }
                  { title }
                </CollectionLink>
              ))}
              <CollectionLink onClick={toggleSearchBar} to={'#'}>
                <FontAwesomeIcon icon={faSearch} size="2x"/>
                Search
              </CollectionLink>
            </>
          }
        </Container>
      </Wrapper >
    </>
  )
}

export default CollectionBar
