import styled from '@emotion/styled';
import { SearchBox as AlgoliaSearchBox } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skyBlue, oceanBlue, copper, copper20 } from '../../constants/theme';
import { breakpoints } from '../../utils/styles';
import { Img } from '../../utils/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
  font-family: 'HKNova-Regular';

  .search-close {
    background: transparent;
    border: none;
    padding: 0 2rem;
    cursor: pointer;
    outline: none;
    border-bottom: 3px solid ${skyBlue};

    &:focus {
      border-color: ${oceanBlue};
    }

    path {
      fill: white;
    }
  }
`

export const Results = styled.div`
  background-color: white;
  position: relative;
  overflow-y: scroll;
  max-height: calc(100vh - 175px);
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  font-family: HKNova-Medium;
  text-transform: none;
  letter-spacing: normal;

  a {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints.m}px){
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.s}px){
    font-size: 12px;
  }
`

export const Result = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 3.75rem;

  &:hover {
    background-color: ${copper20};
  }
`

export const ResultLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ResultImage = styled.div`
  width: 3rem;
  min-width: 3rem;
  display: flex;
  padding: .5rem;
  justify-content: center;
  margin: 0 .5rem;
`

export const Icon = styled(FontAwesomeIcon)`
  color: ${copper};
`

export const Title = styled.div`
  color: ${oceanBlue};
`

export const PriceTag = styled.div`
  padding: 1rem;

  div {
    color: ${skyBlue};

    span {
      text-decoration: line-through;
      padding-left: 5px;
      opacity: .6;
    }
  }
`

export const SearchBox = styled(AlgoliaSearchBox)`
  width: 100%;

  input {
    width: 100%;
    padding: 1rem;
    border: 0;
    background: transparent;
    color: white;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    height: 96px;
    outline: none;
    border-bottom: 3px solid ${skyBlue};
    border-radius: 0;
    -webkit-appearance: none;

    &:focus {
      border-color: ${oceanBlue};
    }

    &::placeholder {
      color: white;
      opacity: .6;
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }

    /* clears the 'X' from Chrome and Safari */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`

export const Image = styled(Img)`
  padding: .5rem;

  picture img {
    border-radius: 100%;
  }
`