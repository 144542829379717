export const copperGradient = "radial-gradient(circle, rgba(190,163,133,0.3) 0%, rgba(213,207,192,0.5) 100%);";

export const accent = '#D0A968';
export const offwhite = '#EBE5DB';
export const oceanBlue = '#152238';
export const oceanBlue15 = 'rgb(21,34,56,0.15)';
export const oceanBlue30 = 'rgb(21,34,56,0.30)';
export const skyBlue = '#4783b5';
export const skyBlue10 = 'rgba(71,131,181,0.10)';
export const skyBlue30 = 'rgba(71,131,181,0.30)';
export const tealGreen = '#99d8e1';
export const tealGreenLow = 'rgba(153, 216, 225, 0.2)';
export const copper = '#df9978';
export const copper20 = 'rgba(223, 153, 120, .2)';
