import moment from 'moment'
import codes from './discountCodeDates';

let expiredCodes = [];
for (const code in codes) {
  if (codes[code]) {
    if (moment().isAfter(moment(codes[code]))) {
      expiredCodes.push(code);
    }
  }
}

const badCodes = [
  'xmas33',
  'testtest10',
  'codeExpireTest',
  'WINTERSHIP',
  'testest2'
].concat(expiredCodes);

export default badCodes
