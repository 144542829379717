/* DO NOT EDIT OR COMMIT: generated file */ export default {
  "VIP10": null,
  "30SHORTS": "2021-06-10T13:37:35Z",
  "30TSHIRT": "2021-06-10T13:37:35Z",
  "30COPPER": "2020-11-01T03:30:59Z",
  "FREESHIPPING": null,
  "48SALE": "2021-06-10T13:37:35Z",
  "20OFF": "2021-06-10T13:37:35Z",
  "CC20OFF1": "2021-06-10T13:40:47Z",
  "GOLF20": null,
  "TAKE25CC": "2021-06-10T13:45:52Z",
  "NUYEAR25": "2019-02-01T07:30:00Z",
  "COPPER-BFCM25": "2019-12-03T08:00:59Z",
  "COPPERVIP30": "2019-12-03T08:00:59Z",
  "HOLIDAYS20": "2020-01-01T04:59:59Z",
  "PAINFREE": null,
  "STRENGTH-TO-HEAL": "2021-06-10T13:46:06Z",
  "STRENGTHTOHEAL": "2021-06-10T13:46:06Z",
  "Follow20": null,
  "PREZ2020": "2020-02-18T04:59:59Z",
  "Insta2020": null,
  "THANKYOU20": null,
  "BRAHA20": null,
  "BradyBunch40": null,
  "spirit25": "2020-04-03T22:00:59Z",
  "sewingoutloud": "2020-05-12T20:51:59Z",
  "sewingoutloud30": "2020-05-28T17:06:53Z",
  "fhs30": "2021-06-10T13:42:10Z",
  "zestfulaging30": null,
  "outthere30": null,
  "fhs": "2021-06-10T13:42:10Z",
  "copperwelcome": "2021-02-26T20:25:41Z",
  "check20": "2021-06-10T13:40:47Z",
  "coppermom25": "2020-05-11T22:00:59Z",
  "sew-loud": "2021-02-26T20:25:18Z",
  "copperdad30": "2020-06-22T16:00:59Z",
  "welcomeback20": null,
  "morethanmasks": null,
  "july4th20": "2020-07-06T03:59:59Z",
  "WELCOMEBACK-BJ7DBQP8": null,
  "LOYAL20": null,
  "PARENTS20": "2020-07-28T03:59:59Z",
  "mask25": "2020-08-19T17:56:56Z",
  "sleeves30": "2020-08-22T03:59:59Z",
  "arch25": "2020-08-29T03:59:00Z",
  "laborday25": "2020-09-08T07:00:59Z",
  "VIP25": null,
  "FRIENDS50": null,
  "hesscorp30": "2021-03-16T16:02:25Z",
  "twitter30": "2021-03-16T16:02:27Z",
  "shippingfree": "2020-10-05T15:00:59Z",
  "scott20": "2021-03-16T16:02:25Z",
  "DrSchiff": "2021-03-16T16:02:25Z",
  "get-gaiter": "2020-11-14T04:59:59Z",
  "GETGAITER": "2020-11-11T04:59:59Z",
  "TRYCOPPER": null,
  "VIP40": "2020-11-26T04:59:59Z",
  "save15": "2020-12-02T04:59:59Z",
  "save25": "2020-12-02T04:59:59Z",
  "save40": "2020-12-02T04:59:59Z",
  "BF40OFF": "2020-12-05T04:59:00Z",
  "laura25": null,
  "save5": "2020-12-14T04:59:59Z",
  "xmas33": "2020-12-18T04:59:59Z",
  "WINTERSHIP": "2020-12-23T04:59:59Z",
  "testtest10": "2020-12-21T16:15:54Z",
  "codeExpireTest": "2020-12-30T05:43:43Z",
  "BOXINGDAY": "2020-12-27T04:59:59Z",
  "testtest2": "2020-12-30T16:30:42Z",
  "BYE2020": "2021-01-01T04:59:59Z",
  "BEST15": "2021-01-09T04:59:59Z",
  "jill30": "2021-01-14T19:31:29Z",
  "DONTQUIT20": "2021-01-19T04:59:59Z",
  "bwl70": null,
  "DREW20": null,
  "PRESIDENTS": "2021-02-16T05:00:03Z",
  "sitetest30": "2021-02-16T23:38:55Z",
  "TRYCOPPER20": null,
  "JUDITH20": null,
  "JOSH_EXPERIMENT_DONOTUSE": "2021-02-26T17:50:59Z",
  "DAYLIGHT10": "2021-03-16T03:59:59Z",
  "FREESHIPKTQPK56H": null,
  "WELCOME15MZW94THS": null,
  "RETIRED20": "2021-03-20T03:59:59Z",
  "BALANCE15": "2021-03-23T03:59:59Z",
  "WELCOME15": null,
  "WELCOME20": null,
  "BRE20": null,
  "EJ20": null,
  "RAYNIER20": null,
  "WELCOME10CFSF5M5K": null,
  "MASKVIP20": null,
  "VIPMASK20": null,
  "THANKS35WCL326": null,
  "NOFOOLINGQPGH5QSX": null,
  "EASTER25": "2021-04-08T03:59:59Z",
  "APRILFOOLS10": "2021-06-10T13:39:31Z",
  "EASTER25!": "2021-04-08T03:59:59Z",
  "DONNA25": null,
  "COMEBACK9RSZ77Z4": "2021-04-15T16:01:04Z",
  "COMEBACKQRGQL8JK": "2021-04-16T17:00:56Z",
  "COMEBACKLCDKNC28": "2021-04-16T17:00:56Z",
  "COMEBACKJCMQJ9LG": "2021-04-17T17:01:19Z",
  "COMEBACKX38S7ZQB": "2021-04-17T17:01:19Z",
  "SPRINGBOGO50": "2021-04-15T16:26:49Z",
  "COMEBACKB8GT3BC4": "2021-04-18T17:01:23Z",
  "COMEBACK2WWH3PZX": "2021-04-18T17:01:23Z",
  "COMEBACKLF6S857P": "2021-04-19T17:02:14Z",
  "COMEBACK8FDWDW3K": "2021-04-19T17:02:14Z",
  "SPRING25": "2021-04-19T03:59:59Z",
  "COMEBACKM4XL2J2F": "2021-04-20T18:00:50Z",
  "COMEBACKG289WK99": "2021-04-20T18:00:50Z",
  "MOTHER": "2021-05-03T14:00:59Z",
  "COMEBACKMHCS5H6N": "2021-04-21T18:00:51Z",
  "COMEBACK83M42TGP": "2021-04-21T18:00:51Z",
  "COMEBACK44VSH7QJ": "2021-04-22T19:00:51Z",
  "COMEBACKD6WZMSGQ": "2021-04-22T19:00:51Z",
  "COMEBACK2LWXSTDH": "2021-04-23T19:01:01Z",
  "ELINA20": "2021-07-03T13:41:41Z",
  "COMEBACKGQ3NCLT5": "2021-04-25T16:01:19Z",
  "COMEBACKVDRSSG3H": "2021-04-25T16:01:19Z",
  "COMEBACKPQH3VF6W": "2021-04-26T17:01:03Z",
  "COMEBACKQQTKPH4L": "2021-04-26T17:01:03Z",
  "COMEBACKHRCK9TFF": "2021-04-27T17:01:03Z",
  "COMEBACK6VZH8LH6": "2021-04-27T17:01:03Z",
  "COMEBACKDMQ96FXX": "2021-04-28T17:01:10Z",
  "COMEBACKG55FKXR6": "2021-04-28T17:01:10Z",
  "COMEBACKPM97T33F": "2021-04-29T18:00:51Z",
  "COMEBACKHBSK7RFV": "2021-04-29T18:00:51Z",
  "COMEBACK24SFGGWB": "2021-04-30T19:00:51Z",
  "COMEBACKMTVL3BP5": "2021-04-30T19:00:51Z",
  "COMEBACKCLQFFWG7": "2021-05-01T19:01:01Z",
  "COMEBACKHFCJ86H2": "2021-05-01T19:01:01Z",
  "KIDSMASK12": "2021-04-27T19:58:09Z",
  "COPPERVIP20": "2021-04-27T20:00:47Z",
  "COMEBACKD5J527SV": "2021-05-02T20:00:50Z",
  "COMEBACK588LTBSR": "2021-05-03T22:00:50Z",
  "COMEBACK7D46WDSX": "2021-05-05T16:01:24Z",
  "COMEBACK97X7V42S": "2021-05-06T17:00:52Z",
  "COMEBACKHKCSJXGM": "2021-05-06T17:00:52Z",
  "COMEBACK43MDVD45": "2021-05-07T17:01:14Z",
  "COMEBACKGRRNPCTJ": "2021-05-08T18:00:51Z",
  "COMEBACKQQ4JRNJL": "2021-05-08T18:00:52Z",
  "COMEBACK99VMGM6B": "2021-05-09T19:00:55Z",
  "COMEBACK6GJ724NX": "2021-05-09T19:00:55Z",
  "katie20": null,
  "COMEBACKVN8532WX": "2021-05-11T16:01:06Z",
  "COMEBACK2M3BX35S": "2021-05-11T16:01:07Z",
  "RELIEF25": null,
  "COMEBACKX64GS4G5": "2021-05-12T16:01:41Z",
  "COMEBACKSDVMVLCQ": "2021-05-12T16:01:42Z",
  "COMEBACKQNVZNPB9": "2021-05-13T17:00:51Z",
  "COMEBACK8QBGGB9V": "2021-05-13T17:00:51Z",
  "COMEBACKHM47T7CR": "2021-05-14T17:00:52Z",
  "COMEBACKGH58L335": "2021-05-14T17:00:52Z",
  "COMEBACKLBR85NJ5": "2021-05-15T17:00:59Z",
  "COMEBACKKWTM7SPR": "2021-05-15T17:00:59Z",
  "COMEBACKP8HWQD48": "2021-05-16T18:00:51Z",
  "COMEBACKWNG3LWMW": "2021-05-16T18:00:51Z",
  "HPLT30": null,
  "COMEBACKZ6T92SPV": "2021-05-17T19:00:54Z",
  "COMEBACK2RDD4BFV": "2021-05-17T19:00:54Z",
  "RELIEF10": "2021-05-15T14:34:34Z",
  "PAINLESS20": "2021-05-16T03:30:39Z",
  "ASHLEY20": null,
  "COMEBACKC3HHD7TK": "2021-05-18T20:00:50Z",
  "COMEBACK7QTGV76L": "2021-05-19T22:00:51Z",
  "COMEBACKRJS47LNN": "2021-05-19T22:00:51Z",
  "COMEBACKVLJ25L3R": "2021-05-20T22:00:51Z",
  "COMEBACKL43FG67N": "2021-05-22T04:00:50Z",
  "COMEBACKHH4XVHPN": "2021-05-23T16:01:27Z",
  "COMEBACKLHXLLHHK": "2021-05-23T16:01:27Z",
  "COMEBACKGGHG8X6K": "2021-05-24T16:02:10Z",
  "COMEBACK7P4GQZQN": "2021-05-24T16:02:10Z",
  "COMEBACKK2R6MJRM": "2021-05-25T17:00:58Z",
  "COMEBACK8QWT5TW9": "2021-05-25T17:00:58Z",
  "COMEBACKWLT6WFR2": "2021-05-26T18:00:51Z",
  "COMEBACKK83DGH2B": "2021-05-26T18:00:51Z",
  "MEM20": "2021-06-02T03:59:59Z",
  "MEM30": "2021-06-02T03:59:59Z",
  "MEM40": "2021-06-02T03:59:59Z",
  "COMEBACK5SNSK5BL": "2021-05-27T18:00:51Z",
  "COMEBACKDSZT2GRJ": "2021-05-27T18:00:51Z",
  "COMEBACKJX6MXNCG": "2021-05-28T19:00:51Z",
  "COMEBACKD92T3D89": "2021-05-28T19:00:51Z",
  "COMEBACKV5KZ2HVV": "2021-05-29T19:00:52Z",
  "COMEBACKGSM48NNT": "2021-05-29T19:00:52Z",
  "COMEBACKWHVJQQW9": "2021-05-30T19:00:53Z",
  "MAZZA25": null,
  "COMEBACK8HMG2R83": "2021-05-31T20:00:51Z",
  "COMEBACK459VKSCL": "2021-06-01T22:00:51Z",
  "COMEBACKT75C7N52": "2021-06-03T16:01:18Z",
  "COMEBACKN6L6SG2N": "2021-06-03T16:01:18Z",
  "COMEBACKXB7VKVPC": "2021-06-04T16:01:23Z",
  "COMEBACKBLQZNXD2": "2021-06-04T16:01:23Z",
  "COMEBACKHHH5P75B": "2021-06-05T17:00:52Z",
  "COMEBACKM5SWGRP7": "2021-06-05T17:00:52Z",
  "COMEBACKK9HFNFCW": "2021-06-06T17:00:56Z",
  "COMEBACKTBC4KDV6": "2021-06-07T17:01:03Z",
  "STOVE25": null,
  "COMEBACK37K9NBWW": "2021-06-08T18:00:50Z",
  "COMEBACK2V35JH82": "2021-06-08T18:00:51Z",
  "DAD": null,
  "POWERKNIT": "2021-06-06T04:00:26Z",
  "COMEBACK9XJWBRLT": "2021-06-09T18:00:51Z",
  "COMEBACK7496F2D9": "2021-06-09T18:00:51Z",
  "COMEBACKWJ3W2L36": "2021-06-10T19:00:52Z",
  "COMEBACKKWKNXLHV": "2021-06-10T19:00:52Z",
  "COMEBACKP6BRQZ6M": "2021-06-11T19:00:53Z",
  "COMEBACKWCTRGN76": "2021-06-11T19:00:53Z",
  "COMEBACKFWX8WWQ8": "2021-06-12T20:00:50Z",
  "COMEBACK9DSKFBX5": "2021-06-13T20:00:51Z",
  "COMEBACK5LP38P8B": "2021-06-13T20:00:51Z",
  "BACKPACKERS20": null,
  "COMEBACK22NKW5CM": "2021-06-15T11:00:51Z",
  "COMEBACKHZSDSWCP": "2021-06-16T16:01:00Z",
  "COMEBACK8TZ9XZF6": "2021-06-16T16:01:00Z",
  "COMEBACKDS4MCHMW": "2021-06-17T16:01:12Z",
  "COMEBACKT6M8RVTQ": "2021-06-17T16:01:12Z",
  "COMEBACK54RN8DF4": "2021-06-18T17:00:53Z",
  "COMEBACKXLR6S6VZ": "2021-06-18T17:00:53Z"
};